import { Cross, InfoCircle } from '@/utils/icons'
export const inputMixin = {
    emits: ['update:modelValue', 'changeValue'],

    props: {
      modelValue: {           
        type: Object,
        default: () => ({}),
      }
    },

    computed:{
      inputModel: {
        get() {
          return this.modelValue
        },

        set(value) {
          this.$emit('update:modelValue', value)
        }
      }
    },

    watch: {
      'inputModel.value'() {
        this.validate()
      }
    },

    data() {
      return { Cross, InfoCircle }
    },

    methods: {
      validate() {}
    },
}