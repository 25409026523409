export class GrigOptions {
  isImport = false
  importButtonText = 'Import'
  isFilters = false
  isSelectable = true
  isMultiselect = true
  isSortable = true
  isMultisort = true
  isEditable = false
  isDelete = false
  isAdd = false
  isExportToCSV = false
  addButtonText = 'Add'
  isCardEditable = false
  isSetColumns = false
  isCopyLink = false
  deleteButtonText = 'Delete'
  exportButtonText = 'Export to CSV'
  isShowAllColumns = false
  deleteModalOptions = { hideYes: false, hideNo: false }
  getDeleteContent = async () => {
    return ''
  }
  pathToEdit = null
  isHideFooter = false
  customFooterComponent = null
  titleTableButtons = []

  constructor(data) {
    if (!data) return

    for (const key in data) {
      if (Object.keys(this).includes(key)) this[key] = data[key]
    }
  }
}

export class GridTitleButton {
  id = null
  image = null
  text = ''
  isVisible = true
  onClick = () => {}
  isFile = false
  buttonClass = ''
  imageClass = ''

  constructor(data) {
    if (!data) return

    for (const key in data) {
      if (Object.keys(this).includes(key)) this[key] = data[key]
    }
  }
}
