<template>
    <div class="main-input" :class="{error: !inputModel.isValid, [inputModel.class]: inputModel.class, 'left-icon': inputModel.leftIcon}">
    <div v-if="inputModel.leftIcon" v-html="inputModel.leftIcon" class="l-icon"></div>
      <input type="text" 
        :placeholder="inputModel.placeholder" 
        :readonly="inputModel.readonly" 
        :minlength="inputModel.minlength" 
        :maxlength="inputModel.maxlength" 
        v-model="inputModel.value"/>
        <div class="clearable" v-if="inputModel.isValid && !inputModel.readonly && inputModel.clearable && inputModel.value" @click="inputModel.value = null" v-html="Cross"></div>
        <div class="error-icon" v-if="!inputModel.isValid" v-html="InfoCircle"></div>
    </div>   
</template>

<script>
import { inputMixin } from '@/utils/input-mixin'
/*
medolValue: {
  placeholder: 'Search target pages, anchor',
  value: null,
  isValid: true,
  clearable: false,
  required: false,
  readonly: false,
  maxlength: 5,
  minlength: 1,
  class: 'lg',
  leftIcon: Search, // import from icons.js
  errorKey: ''
}
*/
export default {
  name: 'InputText',
  mixins: [inputMixin],

  methods: {
      validate() {
          if(this.inputModel.required && !this.inputModel.value) {
            this.inputModel.isValid = false
            this.inputModel.errorKey = 'required'
            return
          }

          if(this.inputModel?.regexp && !this.inputModel?.regexp.test(this.inputModel.value.toLowerCase())) {
            this.inputModel.isValid = false
            this.inputModel.errorKey = 'regexp'
            return
          }
          this.inputModel.errorKey = null
          this.inputModel.isValid = true
      }
  },
}
</script>
