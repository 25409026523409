<template>
    <Teleport to="body">
        <div class="modal-container alert-yes-no">
            <div class="modal-block">
                <div class="modal-title">
                    <div class="title-text">
                        <div>{{title}}</div> 
                    </div>
                </div>
                <div class="modal-body">
                    <div class="modal-row" v-html="text">
                    </div>
                    <div class="modal-row buttons">
                        <div v-if="!hideYes" class="main-button orange" @click="alertResult(true)">Yes</div>
                        <div v-else></div>
                        <div v-if="!hideNo" class="main-button" @click="alertResult(false)">No</div>
                        <div v-else></div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport> 
</template>

<script>

export default {
    name: 'AlertYesNo',
    emits: ['update:modelValue', 'alertResult'],
    props: {
        modelValue: {           
            type: Boolean,
            default: () => true,
        },
        title: {
            type: String,
            default: 'Alert'
        },
        text: {
            type: String,
            default: ''
        },
        alertResult: {
            type: Function,
            default: () => {}
        },
        hideYes: {
            type: Boolean,
            default: false
        },
        hideNo: {
            type: Boolean,
            default: false
        },
    },

    computed:{
        columnsModel: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>

<style lang="scss">
.alert-yes-no {
.buttons {
    justify-content: space-between;
    .main-button {
        width: 80px;
        justify-content: center;
        &:first-of-type {
            margin-right: 40px;
        }
    }
}

}

</style>