export class GridColumn {
  type = null
  keyUrl = null
  key = ''
  text = ''
  isHide = false
  position = 0
  isHideDefault = false
  positionDefault = 0
  formatedValue = ({ value /*, row, options */ }) => {
    if (value || value === 0 || value === false) return value
    return ''
  }
  width = '200px'
  cssClass = null
  cssStyles = null

  sortType = null // asc, desc
  sortCount = null

  constructor(data) {
    if (!data) return

    for (const key in data) {
      if (Object.keys(this).includes(key)) this[key] = data[key]
    }
  }
}
