<template>
  <Teleport to="body">
    <div class="modal-container columns">
      <div class="modal-block">
        <div class="modal-title">
          <div class="title-text">
            <div
              class="icon"
              v-html="isSelectAllColumns === 1 ? CheckboxChecked : Checkbox"
              @click="setIsSelectAllColumnsHead"
            ></div>
            <div>Set columns</div>
          </div>
          <div class="title-icons">
            <div
              class="title-icon apply"
              @click="setColumns"
              v-html="ApplyModal"
            ></div>
            <div
              class="title-icon cancel"
              @click="isVisiblePopup = !isVisiblePopup"
              v-html="CancelModal"
            ></div>
          </div>
        </div>
        <div class="modal-body">
          <draggable @end="changePosition">
            <div
              class="modal-row move"
              v-for="column in columnsLocal"
              :key="column.key"
            >
              <div
                class="icon"
                v-html="!column.isHide ? CheckboxChecked : Checkbox"
                @click="setColumnsVisibility(column)"
              ></div>
              <div>{{ column.text }}</div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import {
  CancelModal,
  CheckboxChecked,
  ApplyModal,
  Checkbox,
} from '@/utils/icons'
import { VueDraggableNext } from 'vue-draggable-next'
export default {
  name: 'SetColumn',
  emits: ['update:modelValue', 'setColumns'],
  components: {
    draggable: VueDraggableNext,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: () => true,
    },
    columns: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      CancelModal,
      CheckboxChecked,
      ApplyModal,
      Checkbox,
      isSelectAllColumns: 0,
      columnsLocal: [],
    }
  },

  created() {
    document.body.classList.add('overflow-hide')
    this.columnsLocal = this.columns.map((item) => {
      return { ...item }
    })
  },

  beforeUnmount() {
    document.body.classList.remove('overflow-hide')
  },

  computed: {
    isVisiblePopup: {
      get() {
        return this.modelValue
      },

      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  watch: {
    isSelectAllColumns() {
      let isHide = false
      if (this.isSelectAllColumns === 1) isHide = false
      else if (this.isSelectAllColumns === 0) isHide = true
      else return

      this.columnsLocal.find((item) => {
        item.isHide = isHide
      })
    },
  },

  methods: {
    setIsSelectAllColumnsHead() {
      this.isSelectAllColumns = this.isSelectAllColumns !== 1 ? 1 : 0
    },

    setColumnsVisibility(column) {
      column.isHide = !column.isHide
      if (column.isHide) this.isSelectAllColumns = -1
    },

    setColumns() {
      this.$emit('setColumns', this.columnsLocal)
      this.isVisiblePopup = false
    },

    changePosition(e) {
      this.columnsLocal[e.oldIndex].position = e.newIndex

      if (e.newIndex < e.oldIndex) {
        let idx = e.newIndex
        for (let i = e.newIndex; i < this.columnsLocal.length; ++i) {
          if (this.columnsLocal[i].key === this.columnsLocal[e.oldIndex].key)
            continue

          this.columnsLocal[i].position = ++idx
        }
      } else {
        let idx = e.newIndex
        for (let i = e.newIndex; i >= 0; --i) {
          if (this.columnsLocal[i].key === this.columnsLocal[e.oldIndex].key)
            continue

          this.columnsLocal[i].position = --idx
        }
      }

      this.columnsLocal.sort((a, b) => a.position - b.position)
    },
  },
}
</script>
